
import axios from 'axios';
import { notification } from 'ant-design-vue';

const service = axios.create({
  timeout: 15000,
});


// 添加请求拦截器
service.interceptors.request.use(

  config => {

    // 请求发送前进行处理
    return config;
  },
  error => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  response => {
    const { data } = response;
    return data;
  },
  error => {
    notification.error({
      message: error.response.data.message,
    });
    return error;
  }
);


export default service;
